import React, { useContext, useState, useEffect } from "react";
import "./ScheduleClassesView.scss";
import ScheduleDay from "./ScheduleDay";
import DataContext from "../contexts/DataContext";
import FilterContext from "../contexts/FilterContext";

import { timeSpan, formatDate } from "../helpers/ClassTime";
import DetailsContext from "../contexts/DetailsContext";
import ScheduleClassDetails from "./ScheduleClassDetails";
import { FaSpinner } from "react-icons/fa";
import ScheduleTable from "./ScheduleTable";
import ScheduleHolidayTable from "./ScheduleHolidayTable";
import moment from "moment";

export default function ScheduleWeek(props) {
  const dataContext = useContext(DataContext);
  const detailsContext = useContext(DetailsContext);
  const [filteredData, setFilteredData] = useState(null);
  const { isSidebarVisible, dates, hideEmptyDays, tableView } =
    useContext(FilterContext);
  const { dataLoading, data } = useContext(DataContext);
  const [selectedDates, setSelectedDates] = useState([]);

  const prepareFilterData = () => {
    if (!data) return;
    let preparedData = {};
    for (let row of data) {
      let startDate = moment.utc(row.term_start * 1000);
      let endDate = moment.utc(row.term_end * 1000);
      let selectedEndDate = moment.utc(dates.end);
      let selectedStartDate = moment.utc(dates.start);
      let exceptions = row.exceptions.map((r) => r.date);
      endDate = selectedEndDate > endDate ? endDate : selectedEndDate;
      startDate = startDate < selectedStartDate ? selectedStartDate : startDate;
      let formattedDate = formatDate(startDate);
      let weekday = startDate.format("dddd").toLowerCase();

      if (!exceptions.includes(formattedDate) && weekday === row.day) {
        if (preparedData[formattedDate]) {
          preparedData[formattedDate].push(row);
        } else {
          preparedData[formattedDate] = [row];
        }
      }
      startDate.set("date", startDate.date() + 1);
      weekday = startDate.format("dddd").toLowerCase();
      while (startDate <= endDate) {
        formattedDate = formatDate(startDate);
        if (!exceptions.includes(formattedDate) && weekday === row.day) {
          if (preparedData[formattedDate]) {
            preparedData[formattedDate].push(row);
          } else {
            preparedData[formattedDate] = [row];
          }
        }
        startDate.set("date", startDate.date() + 1);
        weekday = startDate.format("dddd").toLowerCase();
      }
    }
    setFilteredData(preparedData);
  };

  const prepareDates = () => {
    const allSelectedDates = [dates.start];
    let currDate = moment.utc(dates.start);
    while (currDate < moment.utc(dates.end)) {
      currDate.set("date", currDate.date() + 1);
      allSelectedDates.push(formatDate(currDate));
    }
    setSelectedDates(allSelectedDates);
  };

  const ClassesView = () => {
    if (tableView === "classes") {
      return <ScheduleTable />;
    } else if (tableView === "holidays") {
      return <ScheduleHolidayTable />;
    } else if (Object.keys(filteredData).length) {
      return (hideEmptyDays ? Object.keys(filteredData) : selectedDates).map(
        (date, index) => {
          return (
            <ScheduleDay
              key={index}
              date={date}
              data={filteredData[date]}
              timeSpan={timeSpanData}
            />
          );
        }
      );
    }
    return <div className="ScheduleNoClass">No classes found</div>;
  };

  useEffect(() => {
    prepareFilterData();
    prepareDates();
  }, [data]);

  if (dataLoading || !data)
    return (
      <div className="spinner-loader-container">
        <FaSpinner className="spinner-loader" />
      </div>
    );
  let timeSpanData = null;
  if (data) {
    timeSpanData = timeSpan(dataContext.data);
  }

  return (
    <div
      className="ScheduleView"
      style={{ width: isSidebarVisible ? "80%" : "100%" }}
    >
      {props.error ? (
        <div>Something went wrong</div>
      ) : (
        <>
          <div className="view">
            <ClassesView />
          </div>
          {detailsContext.details ? (
            <ScheduleClassDetails data={detailsContext.details} />
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
}
