import { createContext } from "react";

const DataContext = createContext({
  data: null,
  setData: (data) => {},
  dataLoading: false,
  setDataLoading: (data) => {},
  requestKey: 0,
  setRequestKey: (data) => {},
  holidaysLoading: false,
  setHolidaysLoading: (data) => {},
  holidaysData: [],
  setHolidaysData: (data) => {},
  teacherList: [],
  setTeacherList: (data) => {},
});

export const DataProvider = DataContext.Provider;
export const DataConsumer = DataContext.Consumer;

export default DataContext;
