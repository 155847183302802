import React, { useContext, useState } from "react";
import "./ScheduleTable.scss";
import DataContext from "../contexts/DataContext";
import { FaEdit, FaSpinner, FaTrash } from "react-icons/fa";
import DetailsContext from "../contexts/DetailsContext";
import { formatDateReadable } from "../helpers/ClassTime";
import ScheduleClassManager from "./ScheduleClassManager";
import ScheduleAlert from "./ScheduleAlert";
import moment from "moment";

export default function ScheduleHolidayTable() {
  const { holidaysData, dataLoading, setRequestKey, requestKey } =
    useContext(DataContext);
  const { isAdminLogged } = useContext(DetailsContext);
  const [deleting, setDeleting] = useState(0);
  const [editRow, setEditRow] = useState({});
  const [response, setResponse] = useState({});

  const reloadClasses = () => {
    setTimeout(() => {
      setRequestKey(requestKey + 1);
    }, 2000);
  };

  const deleteHoliday = (id) => {
    if (deleting) return;
    if (window.confirm("Delete holiday?")) {
      setDeleting(id);
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/schedule-holidays/delete/${id}`,
        {
          method: "DELETE",
          mode: "cors",
          cache: "no-cache",
          credentials: "include",
          redirect: "follow",
          referrerPolicy: "no-referrer",
        }
      )
        .then((response) => response.json())
        .then((r) => {
          setDeleting(0);
          if (r?.status !== "success") {
            setResponse({
              type: "error",
              message: "Failed to delete holiday.",
            });
          } else {
            setResponse(r);
            reloadClasses();
          }
        })
        .catch((e) => {
          setDeleting(0);
          setResponse({
            status: "error",
            message: "Failed to delete holiday",
          });
          reloadClasses();
          console.error(e);
        });
    }
  };

  return (
    <div className="ScheduleTable">
      {dataLoading ? (
        <FaSpinner className="spinner-loader" />
      ) : (
        <table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Description</th>
              <th>Date</th>
              {isAdminLogged ? <th>Actions</th> : ""}
            </tr>
          </thead>
          <tbody>
            {holidaysData.map((row) => {
              return (
                <tr className="ScheduleTableRows" key={row.id}>
                  <td>{row.name}</td>
                  <td>{row.description}</td>
                  <td>{formatDateReadable(moment.utc(row.date))}</td>
                  {isAdminLogged ? (
                    <td>
                      <div className="ScheduleTableActions">
                        <button
                          className="ScheduleTableBtn"
                          onClick={(e) => deleteHoliday(row.id)}
                          disabled={deleting}
                        >
                          {deleting === row.id ? (
                            <FaSpinner className="spinner-loader" />
                          ) : (
                            <FaTrash className="ScheduleTableBtnIcon" />
                          )}
                        </button>
                        <button
                          className="ScheduleTableBtn"
                          onClick={(e) =>
                            setEditRow({ ...row, title: row.name })
                          }
                          disabled={deleting}
                        >
                          <FaEdit className="ScheduleTableBtnIcon" />
                        </button>
                      </div>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {response.status ? (
        <ScheduleAlert type={response.status} message={response.message} />
      ) : (
        ""
      )}
      <ScheduleClassManager
        manualTrigger={editRow.id ? true : false}
        editHoliday={editRow.id ? editRow : {}}
        close={() => setEditRow({})}
      />
    </div>
  );
}
