import React, { useContext, useEffect, useState } from "react";
import {
  FaCheckSquare,
  FaPlus,
  FaSpinner,
  FaSquare,
  FaTimes,
} from "react-icons/fa";
import "./ScheduleClassManager.scss";
import { Grades } from "../helpers/Constants";
import { convertToAmPm, formatDate } from "../helpers/ClassTime";
import DataContext from "../contexts/DataContext";
import ScheduleDropdown from "./ScheduleDropdown";
import moment from "moment";

export default function ScheduleClassManager(props) {
  const [classFormOpen, setClassFormOpen] = useState(false);
  const [holidayFormOpen, setHolidayFormOpen] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showCreateButtons, setShowCreateButtons] = useState(false);
  const { setRequestKey, requestKey, teacherList } = useContext(DataContext);

  const [form, setForm] = useState({
    title: "",
    grades: [],
    teacher: [],
    term_start_date: "",
    term_end_date: "",
    class_start_time: "",
    class_end_time: "",
    hourly_rate: 0,
    is_full: false,
    is_almost_full: false,
    is_multiclass: false,
    is_online: false,
    is_onsite: false,
  });

  const [holidayForm, setHolidayForm] = useState({
    title: "",
    description: "",
    date: "",
  });

  const [showValidation, setShowValidation] = useState(false);
  const [showHolidayFormValidation, setShowHolidayFormValidation] =
    useState(false);

  const handleClickOutside = (e) => {
    const id = e.target.id;
    if (id === "eventManager") {
      setClassFormOpen(false);
      setShowCreateButtons(false);
      setHolidayFormOpen(false);
      if (props.close) {
        props.close();
      }
    }
  };

  const handleFormInputs = (e, isArray = false) => {
    const { name, value } = e.target;

    if (isArray) {
      let arr = [...form[name]];
      const index = arr.indexOf(value);
      if (index > -1) {
        arr.splice(index, 1);
      } else {
        arr.push(value);
      }
      setForm({ ...form, [name]: arr });
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const handleHolidayForm = (e) => {
    const { name, value } = e.target;
    setHolidayForm({ ...holidayForm, [name]: value });
  };

  const saveClass = () => {
    setShowValidation(true);
    const data = validateData();

    if (data) {
      setSaving(true);
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/schedule-classes/store`, {
        method: "POST",
        body: JSON.stringify(data),
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        redirect: "follow",
        referrerPolicy: "no-referrer",
      })
        .then((r) => {
          setSaving(false);
          if (r.status !== 200) {
            setApiError(true);
          } else {
            setClassFormOpen(false);
            setApiError(false);
            setRequestKey(requestKey + 1);
          }
        })
        .catch((e) => {
          setApiError(true);
          setSaving(false);
          console.error(e);
        });
    }
  };

  const updateClass = () => {
    setShowValidation(true);
    const data = validateData();

    if (data) {
      delete data.id;
      setSaving(true);
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/schedule-classes/update/${props.editClass.id}`,
        {
          method: "PUT",
          body: JSON.stringify(data),
          mode: "cors",
          cache: "no-cache",
          credentials: "include",
          redirect: "follow",
          referrerPolicy: "no-referrer",
        }
      )
        .then((r) => {
          setSaving(false);
          if (r.status !== 200) {
            setApiError(true);
          } else {
            setApiError(false);
            setClassFormOpen(false);
            setRequestKey(requestKey + 1);
          }
        })
        .catch((e) => {
          setApiError(true);
          setSaving(false);
          console.error(e);
        });
    }
  };

  const saveHoliday = () => {
    setShowHolidayFormValidation(true);
    const data = validateHolidayData();

    if (data) {
      setSaving(true);
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/schedule-holidays/store`, {
        method: "POST",
        body: JSON.stringify(data),
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        redirect: "follow",
        referrerPolicy: "no-referrer",
      })
        .then((r) => {
          setSaving(false);
          if (r.status !== 200) {
            setApiError(true);
          } else {
            setApiError(false);
            setHolidayFormOpen(false);
            setRequestKey(requestKey + 1);
          }
        })
        .catch((e) => {
          setApiError(true);
          setSaving(false);
          console.error(e);
        });
    }
  };

  const updateHoliday = () => {
    setShowHolidayFormValidation(true);
    const data = validateHolidayData();

    if (data) {
      delete data.id;
      setSaving(true);
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/schedule-holidays/update/${props.editHoliday.id}`,
        {
          method: "PUT",
          body: JSON.stringify(data),
          mode: "cors",
          cache: "no-cache",
          credentials: "include",
          redirect: "follow",
          referrerPolicy: "no-referrer",
        }
      )
        .then((r) => {
          setSaving(false);
          if (r.status !== 200) {
            setApiError(true);
          } else {
            setApiError(false);
            setHolidayFormOpen(false);
            setRequestKey(requestKey + 1);
          }
        })
        .catch((e) => {
          setApiError(true);
          setSaving(false);
          console.error(e);
        });
    }
  };

  const validateHolidayData = () => {
    const data = { ...holidayForm };
    if (data.date) {
      data.date = moment.utc(data.date);
    }

    if (data.title) {
      return data;
    }
    return false;
  };

  const validateData = () => {
    const data = {
      ...form,
      hourly_rate: parseFloat(form.hourly_rate),
      is_full: Boolean(form.is_full) ? 1 : 0,
      is_almost_full: Boolean(form.is_almost_full) ? 1 : 0,
      is_multiclass: Boolean(form.is_multiclass) ? 1 : 0,
      is_online: Boolean(form.is_online) ? 1 : 0,
      is_onsite: Boolean(form.is_onsite) ? 1 : 0,
    };
    if (
      data.title &&
      data.term_start_date &&
      data.term_end_date &&
      data.class_start_time &&
      data.class_end_time &&
      data.hourly_rate > 0 &&
      data.teacher.length &&
      data.grades.length
    ) {
      data.class_start_time = convertToAmPm(data.class_start_time);
      data.class_end_time = convertToAmPm(data.class_end_time);
      data.term_start_date = formatDate(moment.utc(data.term_start_date));
      data.term_end_date = formatDate(moment.utc(data.term_end_date));
      data.term_start_date = formatDate(moment.utc(data.term_start_date));
      data.term_end_date = formatDate(moment.utc(data.term_end_date));
      data.teacher = data.teacher.join(" ");

      return data;
    }
    return false;
  };

  useEffect(() => {
    if (props.editClass && props.editClass.id) {
      setForm({
        ...props.editClass,
        teacher: props.editClass.teacher.split(" "),
      });
    }
  }, [props.editClass]);

  useEffect(() => {
    if (props.editHoliday && props.editHoliday.id) {
      setHolidayForm({ ...props.editHoliday });
    }
  }, [props.editHoliday]);

  return (
    <div className="ClassManager">
      {!props.hasOwnProperty("manualTrigger") ? (
        <div className="CreateButtonContainer">
          <button
            className="CreateButton"
            onClick={() => setShowCreateButtons(!showCreateButtons)}
            style={{
              background: showCreateButtons ? "#3c4044" : "#ffffff",
              color: showCreateButtons ? "white" : "#3c4044",
            }}
          >
            {showCreateButtons ? <FaTimes /> : <FaPlus />}
            Create
          </button>
          {showCreateButtons && !classFormOpen ? (
            <div className="CreateButtonGroup">
              <button
                onClick={() => {
                  setClassFormOpen(!classFormOpen);
                  setShowCreateButtons(false);
                }}
              >
                Class
              </button>
              <button
                onClick={() => {
                  setHolidayFormOpen(!holidayFormOpen);
                  setShowCreateButtons(false);
                }}
              >
                Holiday
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      {classFormOpen || (props.editClass && props.manualTrigger) ? (
        <div
          className="ScheduleModal"
          onClick={(e) => handleClickOutside(e)}
          id="eventManager"
        >
          <div className="ScheduleModalContent">
            <div className="ScheduleModalHead">Class Schedule </div>
            <div className="ScheduleModalBody">
              <div id="ClassForm">
                <div className="ClassFormInput">
                  <label htmlFor="classTitle">Title</label>
                  <input
                    id="classTitle"
                    name="title"
                    placeholder="Enter class title"
                    value={form.title}
                    onChange={handleFormInputs}
                  />
                  <small className="ClassManagerError">
                    {showValidation && !form.title
                      ? "Please enter a title"
                      : ""}
                  </small>
                </div>
                <div className="ClassFormInput">
                  <label htmlFor="grades">Grades</label>
                  <ScheduleDropdown
                    items={Grades}
                    isMultiple={true}
                    defaultSelected={form.grades}
                    onChange={(grade) =>
                      handleFormInputs(
                        { target: { name: "grades", value: grade } },
                        true
                      )
                    }
                  />
                  <small className="ClassManagerError">
                    {showValidation && !form.grades.length
                      ? "Please select a grade"
                      : ""}
                  </small>
                </div>
                <div className="ClassFormInput">
                  <label htmlFor="teacherName">Teacher</label>
                  <ScheduleDropdown
                    items={teacherList.map((t) => t.name)}
                    isMultiple={true}
                    defaultSelected={form.teacher}
                    onChange={(teacher) =>
                      handleFormInputs(
                        { target: { name: "teacher", value: teacher } },
                        true
                      )
                    }
                  />
                  <small className="ClassManagerError">
                    {showValidation && !form.teacher.length
                      ? "Please select a teacher"
                      : ""}
                  </small>
                </div>
                <div className="ClassTimeInputs">
                  <div className="ClassFormInput">
                    <label htmlFor="startDate">Term start date</label>
                    <input
                      id="startDate"
                      type="date"
                      value={form.term_start_date}
                      name="term_start_date"
                      onChange={handleFormInputs}
                    />
                    <small className="ClassManagerError">
                      {showValidation && !form.term_start_date
                        ? "Please select a start date"
                        : ""}
                    </small>
                  </div>

                  <div className="ClassFormInput">
                    <label htmlFor="endDate">Term end date</label>
                    <input
                      id="endDate"
                      type="date"
                      value={form.term_end_date}
                      name="term_end_date"
                      onChange={handleFormInputs}
                    />
                    <small className="ClassManagerError">
                      {showValidation && !form.term_end_date
                        ? "Please select a end date"
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="ClassTimeInputs">
                  <div className="ClassFormInput">
                    <label htmlFor="startTime">Class start time</label>
                    <input
                      id="startTime"
                      type="time"
                      value={form.class_start_time}
                      name="class_start_time"
                      onChange={handleFormInputs}
                    />
                    <small className="ClassManagerError">
                      {showValidation && !form.class_start_time
                        ? "Please select class start time"
                        : ""}
                    </small>
                  </div>

                  <div className="ClassFormInput">
                    <label htmlFor="endTime">Class end time</label>
                    <input
                      id="endTime"
                      type="time"
                      value={form.class_end_time}
                      name="class_end_time"
                      onChange={handleFormInputs}
                    />
                    <small className="ClassManagerError">
                      {showValidation && !form.class_end_time
                        ? "Please select class end time"
                        : ""}
                    </small>
                  </div>
                </div>

                <div className="ClassFormInput">
                  <label htmlFor="hourlyRate">Hourly rate</label>
                  <input
                    id="hourlyRate"
                    type="number"
                    value={form.hourly_rate}
                    name="hourly_rate"
                    onChange={handleFormInputs}
                    min="0"
                  />
                  <small className="ClassManagerError">
                    {showValidation && form.hourly_rate < 1
                      ? "Please select a valid hourly rate"
                      : ""}
                  </small>
                </div>
                <label>Options</label>
                <div className="ClassCheckboxGroup">
                  <label
                    className="ClassCheckbox"
                    onClick={() =>
                      handleFormInputs({
                        target: { name: "is_full", value: !form.is_full },
                      })
                    }
                  >
                    {form.is_full ? (
                      <FaCheckSquare className="CheckBoxIcons" />
                    ) : (
                      <FaSquare className="CheckBoxIcons" />
                    )}
                    Full
                  </label>

                  <label
                    className="ClassCheckbox"
                    onClick={() =>
                      handleFormInputs({
                        target: {
                          name: "is_almost_full",
                          value: !form.is_almost_full,
                        },
                      })
                    }
                  >
                    {form.is_almost_full ? (
                      <FaCheckSquare className="CheckBoxIcons" />
                    ) : (
                      <FaSquare className="CheckBoxIcons" />
                    )}
                    Almost full
                  </label>

                  <label
                    className="ClassCheckbox"
                    onClick={() =>
                      handleFormInputs({
                        target: {
                          name: "is_multiclass",
                          value: !form.is_multiclass,
                        },
                      })
                    }
                  >
                    {form.is_multiclass ? (
                      <FaCheckSquare className="CheckBoxIcons" />
                    ) : (
                      <FaSquare className="CheckBoxIcons" />
                    )}
                    Multiclass
                  </label>

                  <label
                    className="ClassCheckbox"
                    onClick={() =>
                      handleFormInputs({
                        target: {
                          name: "is_online",
                          value: !form.is_online,
                        },
                      })
                    }
                  >
                    {form.is_online ? (
                      <FaCheckSquare className="CheckBoxIcons" />
                    ) : (
                      <FaSquare className="CheckBoxIcons" />
                    )}
                    Online
                  </label>

                  <label
                    className="ClassCheckbox"
                    onClick={() =>
                      handleFormInputs({
                        target: {
                          name: "is_onsite",
                          value: !form.is_onsite,
                        },
                      })
                    }
                  >
                    {form.is_onsite ? (
                      <FaCheckSquare className="CheckBoxIcons" />
                    ) : (
                      <FaSquare className="CheckBoxIcons" />
                    )}
                    Onsite
                  </label>
                </div>
              </div>
            </div>
            <div className="ScheduleModalFoot">
              <small className="ClassManagerError">
                {apiError
                  ? `Failed to ${
                      props?.editClass?.id ? "update" : "schedule"
                    } class`
                  : ""}
              </small>
              <div className="ScheduleModalActions">
                <button
                  disabled={saving}
                  onClick={() => {
                    setClassFormOpen(false);
                    if (props.close) {
                      props.close();
                    }
                  }}
                >
                  Close
                </button>
                <button
                  className="ConfirmActionButton"
                  disabled={saving}
                  onClick={() =>
                    props.editClass ? updateClass() : saveClass()
                  }
                >
                  {saving ? (
                    <FaSpinner className="spinner-loader" />
                  ) : props.editClass ? (
                    "Update"
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {holidayFormOpen || (props.editHoliday && props.manualTrigger) ? (
        <div
          className="ScheduleModal"
          onClick={(e) => handleClickOutside(e)}
          id="eventManager"
        >
          <div className="ScheduleModalContent">
            <div className="ScheduleModalHead">Holiday Schedule</div>
            <div className="ScheduleModalBody">
              <div id="ClassForm">
                <div className="ClassFormInput">
                  <label htmlFor="holidayTitle">Title</label>
                  <input
                    id="holidayTitle"
                    name="title"
                    placeholder="Enter holiday title"
                    value={holidayForm.title}
                    onChange={handleHolidayForm}
                  />
                  <small className="ClassManagerError">
                    {showHolidayFormValidation && !holidayForm.title
                      ? "Please enter a title"
                      : ""}
                  </small>
                </div>
                <div className="ClassFormInput">
                  <label htmlFor="holidayDescription">
                    Description (optional)
                  </label>
                  <textarea
                    id="holidayDescription"
                    placeholder="Enter holiday description"
                    name="description"
                    value={holidayForm.description}
                    onChange={handleHolidayForm}
                  ></textarea>
                </div>
                <div className="ClassFormInput">
                  <label htmlFor="holidayDate">Date</label>
                  <input
                    type="date"
                    id="holidayDate"
                    name="date"
                    value={holidayForm.date}
                    onChange={handleHolidayForm}
                  />
                </div>
              </div>
            </div>
            <div className="ScheduleModalFoot">
              <small className="ClassManagerError">
                {apiError
                  ? `Failed to ${
                      props?.editHoliday?.id ? "update" : "schedule"
                    } holiday`
                  : ""}
              </small>
              <div className="ScheduleModalActions">
                <button
                  disabled={saving}
                  onClick={() => {
                    setHolidayFormOpen(false);
                    if (props.close) {
                      props.close();
                    }
                  }}
                >
                  Close
                </button>
                <button
                  className="ConfirmActionButton"
                  disabled={saving}
                  onClick={() =>
                    props.editHoliday ? updateHoliday() : saveHoliday()
                  }
                >
                  {saving ? (
                    <FaSpinner className="spinner-loader" />
                  ) : props.editHoliday ? (
                    "Update"
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
