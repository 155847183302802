import { createContext } from "react";

const DetailsContext = createContext({
  details: null,
  setDetails: (details) => {},
  isAdminLogged: false,
  setIsAdminLogged: (details) => {},
});

export const DataProvider = DetailsContext.Provider;
export const DataConsumer = DetailsContext.Consumer;

export default DetailsContext;
