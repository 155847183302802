import React, { useContext } from "react";
import "./ScheduleClass.scss";

import { getStyleObject, timeToFloat, yearFilter } from "../helpers/ClassTime";

import DetailsContext from "../contexts/DetailsContext";

import onsite_icon from "../icons/onsite.svg";
import online_icon from "../icons/online.svg";

export default function ScheduleClass(props) {
  const { setDetails } = useContext(DetailsContext);

  const data = props.data;
  const duration = timeToFloat(data.end) - timeToFloat(data.start);
  const style = {
    top: `calc((((100% - (1em + 1px)) / ${props.division / 12}) * ${
      timeToFloat(data.start) - props.timeSpan.min
    }) + 1em + 30px)`,
    height: `calc(((100% - (1em + 1px)) / ${
      props.division / 12
    }) * ${duration})`,
    width: `calc(100% / ${props.disposition.division})`,
    left: `calc((100% / ${props.disposition.division}) * ${props.disposition.place})`,
  };

  return (
    <article
      data-id={data.id}
      style={{ ...getStyleObject(data), ...style }}
      className={`ScheduleClass ${duration < 1 ? "short" : "long"}`}
      onClick={() => {
        setDetails(data);
      }}
    >
      {duration < 1 ? (
        <>
          <div className="start">
            <div>{data.start}</div>
            {/* {data.class_full && duration <= 1 ? 
                            <div className='alert'><img src="https://waeelearning.com/images/doodles/lock_closed.png" alt="Full" title="Full"/></div>
                        : undefined }
                        {data.class_almost_full && duration <= 1 ? 
                            <div className='alert'><img src="https://waeelearning.com/images/doodles/warning.png" alt="ast spot" title="Last spot"/></div>
                        : undefined } */}
          </div>
          <div className="end">{data.end}</div>
          <div className="title">{data.title}</div>
        </>
      ) : (
        <>
          <div className="start">
            <div>{data.start}</div>
            {data.class_full && duration <= 1 ? (
              <div className="alert">
                <img
                  src="https://waeelearning.com/images/doodles/lock_closed.png"
                  alt="Full"
                  title="Full"
                />
              </div>
            ) : undefined}
            {data.class_almost_full && duration <= 1 ? (
              <div className="alert">
                <img
                  src="https://waeelearning.com/images/doodles/warning.png"
                  alt="ast spot"
                  title="Last spot"
                />
              </div>
            ) : undefined}
          </div>
          {data.class_full && duration > 1 ? (
            <div className="alert">
              <div>
                <img
                  src="https://waeelearning.com/images/doodles/lock_closed.png"
                  alt=""
                />
                Full
              </div>
            </div>
          ) : undefined}
          {data.class_almost_full && duration > 1 ? (
            <div className="alert">
              <div>
                <img
                  src="https://waeelearning.com/images/doodles/warning.png"
                  alt=""
                />
                Last spot
              </div>
            </div>
          ) : undefined}
          <div
            className={`info${
              (data.class_full || data.class_almost_full) && duration > 1
                ? " info-alert"
                : ""
            }`}
          >
            <div className="title">{data.title}</div>
            <div className="online-onsite">
              {data.online ? (
                <img
                  src={online_icon}
                  className="online"
                  alt="online"
                  title="online"
                />
              ) : (
                <span></span>
              )}
              {data.onsite ? (
                <img
                  src={onsite_icon}
                  className="onsite"
                  alt="onsite"
                  title="onsite"
                />
              ) : (
                <span></span>
              )}
            </div>
          </div>
          <div className="end">{data.end}</div>
        </>
      )}
    </article>
  );
}
