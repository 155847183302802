import React, { useContext, useEffect, useState } from "react";
import "./ScheduleDatePicker.scss";
import { FaSpinner, FaArrowRight } from "react-icons/fa";
import FilterContext from "../contexts/FilterContext";
import DataContext from "../contexts/DataContext";
import { formatDate } from "../helpers/ClassTime";

import moment from "moment";

export default function ScheduleDatePicker() {
  const [isInit, setIsInit] = useState(false);
  const [internalDates, setInternalDates] = useState({
    start: "",
    end: "",
  });
  const {
    setDates,
    setTab,
    tab,
    dates,
    hideEmptyDays,
    setHideEmptyDays,
    parentFilters,
  } = useContext(FilterContext);
  const { dataLoading } = useContext(DataContext);

  const setUrlDates = (dateObj = internalDates) => {
    const message = dateValidationMessage();
    if (!message) {
      const params = new URLSearchParams(window.location.search);
      params.set("start_date", dateObj.start);
      params.set("end_date", dateObj.end);
      window.history.replaceState(null, null, "?" + params.toString());

      setDates({ ...dateObj });
      return;
    }
    alert(message);
  };

  const dateValidationMessage = () => {
    const date1Ms = moment.utc(internalDates.start).valueOf();
    const date2Ms = moment.utc(internalDates.end).valueOf();
    const differenceMs = date2Ms - date1Ms;
    const differenceDays =
      Math.floor(differenceMs / (1000 * 60 * 60 * 24)) || 0;

    if (date1Ms > date2Ms) {
      return "Start date cannot be greater than end date.";
    } else if (differenceDays > 120) {
      return "Please select a date range of up to 120 days.";
    }
    return false;
  };

  const getDatesFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    let startDate = moment.utc();
    let endDate = moment.utc();
    if (params.get("start_date")) {
      startDate = moment.utc(params.get("start_date"));
    }

    if (params.get("end_date")) {
      endDate = moment.utc(params.get("end_date"));
    }

    const dateObj = {
      start: formatDate(startDate),
      end: formatDate(endDate),
    };
    setInternalDates(dateObj);
    if (!isInit) {
      setUrlDates(dateObj);
      setIsInit(true);
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInternalDates({
      ...internalDates,
      [name]: formatDate(moment.utc(value)),
    });
    setTab(null);
  };

  const getEmptyDaysFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    const hideDays = params.get("hideEmpty") ? true : false;
    setHideEmptyDays(hideDays);
  };

  const setEmptyDaysFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    if (hideEmptyDays) {
      params.set("hideEmpty", "true");
    } else {
      params.delete("hideEmpty");
    }
    window.history.replaceState(null, null, "?" + params.toString());
  };

  useEffect(() => {
    if (tab) {
      setInternalDates(dates);
      setUrlDates(dates);
    }
  }, [tab]);

  useEffect(() => {
    if (isInit) {
      setEmptyDaysFromUrl();
    }
  }, [hideEmptyDays]);

  useEffect(() => {
    if (parentFilters.dates) {
      const dates = {};
      dates.start = parentFilters.dates.start;
      dates.end = parentFilters.dates.end;
      setInternalDates(dates);
      setDates(dates);
    }
  }, [parentFilters]);

  useEffect(() => {
    getDatesFromUrl();
    getEmptyDaysFromUrl();
    document.querySelector("input").addEventListener("keydown", function (e) {
      if (e.key === "Tab") {
        e.preventDefault();
      }
    });
  }, []);
  return (
    <div className="DatePickerContainer">
      <div className="DatePicker">
        <div className="DatePickerInputGroup">
          <label htmlFor="startDate">Start date</label>
          <input
            id="startDate"
            name="start"
            type="date"
            value={internalDates.start}
            onChange={(e) => handleOnChange(e)}
            disabled={dataLoading}
          />
        </div>

        <div className="DatePickerInputGroup">
          <label htmlFor="endDate">End date</label>
          <input
            id="endDate"
            name="end"
            type="date"
            value={internalDates.end}
            onChange={(e) => handleOnChange(e)}
            disabled={dataLoading}
          />
        </div>
        <button
          className="DatePickerButton"
          onClick={() => setUrlDates()}
          disabled={dataLoading}
        >
          {dataLoading ? (
            <FaSpinner className="spinner-loader" />
          ) : (
            <FaArrowRight />
          )}
        </button>
      </div>
      <div className="DatePickerHideEmpty">
        <label>
          <input
            type="checkbox"
            checked={hideEmptyDays}
            onChange={(e) => setHideEmptyDays(!hideEmptyDays)}
          />
          Hide Days with No Classes
        </label>
      </div>
    </div>
  );
}
