import moment from "moment";
import { Tabs } from "./Constants";
import springImage from "../images/spring-break.svg";
import summerImage from "../images/summer-break.svg";
import winterImage from "../images/winter-break.svg";

export function timeToFloat(value) {
  let time = value.split(/[am|pm]/)[0];
  let [hour, minute] = time.split(":").map((v) => parseInt(v));
  if (value.endsWith("pm") && hour !== 12) hour += 12;

  return hour + parseFloat((minute / 60).toFixed(2));
}

export function floatToTime(value) {
  let hour = Math.trunc(value);
  let minute = Math.round((value - hour) * 60);
  minute = Math.ceil(minute / 5) * 5;
  if (minute >= 60) {
    hour++;
    minute = 0;
  }

  if (minute < 10) minute = `0${minute}`;
  else minute = minute.toString();

  let suffix = "am";
  if (hour >= 12) {
    if (hour > 12) hour -= 12;
    suffix = "pm";
  }
  return `${hour}:${minute}${suffix}`;
}

export function getDuration(time1, time2) {
  time1 = timeToFloat(time1);
  time2 = timeToFloat(time2);
  return time2 - time1;
}

export function timeToDuration(time) {
  let hour = Math.trunc(time);
  let minute = Math.round((time - hour) * 60);

  if (minute === 0) minute = "";
  else if (minute < 10) minute = `0${minute}`;
  else minute = minute.toString();

  return `${hour}h${minute}`;
}

export function timeSpan(data) {
  let min = null;
  let max = null;
  data.map((d, i) => {
    let start = timeToFloat(d.start);
    let end = timeToFloat(d.end);

    if (start < min || min === null) min = start;
    if (end > max || max === null) max = end;

    return true;
  });
  return {
    min: Math.floor(min),
    max: Math.ceil(max),
  };
}

export function getOverlapDataOriginal(data, timeSpan, day) {
  let stacks = [];
  for (let i = timeSpan.min; i <= timeSpan.max; i += 1 / 12) {
    stacks[floatToTime(i)] = [];
  }
  data.forEach((elem) => {
    for (
      let j = timeToFloat(elem.start);
      j < timeToFloat(elem.end) - 1 / 12;
      j += 1 / 12
    ) {
      stacks[floatToTime(j)].push(elem.id);
    }
  });

  let neighbors = [];
  let indexes = [];
  for (let [, stack] of Object.entries(stacks)) {
    stack.forEach((elem, i) => {
      if (indexes[elem] === undefined || indexes[elem] < i) {
        indexes[elem] = i;
      }
      if (neighbors[elem] === undefined) {
        neighbors[elem] = [...stack];
      } else {
        neighbors[elem].push(
          ...stack.filter((v) => !neighbors[elem].includes(v))
        );
      }
    });
  }

  let extendedNeighbors = [];
  neighbors.forEach((n, self) => {
    let candidates = [...n];
    n.forEach((n2) => {
      candidates.push(...neighbors[n2]);
      neighbors[n2].forEach((n3) => {
        candidates.push(...neighbors[n3]);
      });
    });
    extendedNeighbors[self] = [
      ...candidates.filter((item, pos, self) => self.indexOf(item) === pos),
    ];
  });

  let divisions = [];
  extendedNeighbors.forEach((extNeiList, selfId) => {
    let directMax, directMin;
    extNeiList.forEach((extNei) => {
      const directDiv = neighbors[extNei].length;

      if (directMax === undefined || directMax < directDiv) {
        directMax = directDiv;
      }
      if (directMin === undefined || directMin > directDiv) {
        directMin = directDiv;
      }
    });
    if (directMax === extNeiList.length) {
      if (divisions[selfId] === undefined || divisions[selfId] > directMin) {
        divisions[selfId] = directMin;
      }
    } else {
      if (divisions[selfId] === undefined || divisions[selfId] < directMax) {
        divisions[selfId] = directMax;
      }
      const selfPos = neighbors[selfId].sort().indexOf(selfId);
      if (indexes[selfId] < selfPos) indexes[selfId] = selfPos;
    }
  });

  return data.map((d, i) => {
    return {
      division: divisions[d.id],
      width: null,
      place: indexes[d.id],
    };
  });
}

export function getOverlapData(data, timeSpan, day) {
  let stacks = [];
  for (let i = timeSpan.min; i <= timeSpan.max; i += 1 / 12) {
    stacks[floatToTime(i)] = [];
  }
  data.forEach((elem) => {
    for (
      let j = timeToFloat(elem.start);
      j < timeToFloat(elem.end) - 1 / 12;
      j += 1 / 12
    ) {
      const time = floatToTime(j);
      stacks[time].push(elem.id);
    }
  });

  const divisions = [];
  const positions = [];

  data.forEach((elem, i) => {
    for (
      let j = timeToFloat(elem.start);
      j < timeToFloat(elem.end) - 1 / 12;
      j += 1 / 12
    ) {
      const time = floatToTime(j);
      if (
        divisions[elem.id] === undefined ||
        stacks[time].length > divisions[elem.id]
      ) {
        divisions[elem.id] = stacks[time].length;
      }
      const position = stacks[time].indexOf(elem.id);

      if (positions[elem.id] === undefined || position > positions[elem.id]) {
        positions[elem.id] = position;
      }
    }
  });

  return data.map((d, i) => {
    return {
      division: divisions[d.id] ?? 1,
      width: null,
      place: positions[d.id],
    };
  });
}

export function getDateSuffix(date) {
  return (
    moment.utc(date).date() +
    ((date) => {
      if (date === 1 || date === 21 || date === 31) return "st";
      if (date === 2 || date === 22) return "nd";
      if (date === 3 || date === 23) return "rd";
      return "th";
    })(moment.utc(date).date())
  );
}

export function getWeeksCountBetween(d1, d2) {
  const diff = moment.utc(d2).diff(moment.utc(d1), "weeks");
  return diff >= 0 ? diff : 0;
}

export function getExceptionsCountsBetweenDates(
  exceptions,
  d1,
  d2,
  noException = false
) {
  if (noException) return 0;
  let count = 0;
  exceptions.forEach((exception) => {
    const date = moment.utc(exception.date, "YYYY-MM-DD");
    if (date.isBetween(d1, d2, null, "[]")) count++;
  });
  return count;
}

export function getWeeksCounts(data, noException = false) {
  const start = moment.utc(data.term_start * 1000);
  const end = moment.utc(data.term_end * 1000);
  const now = moment.utc();
  const total =
    getWeeksCountBetween(start, end) -
    getExceptionsCountsBetweenDates(data.exceptions, start, end, noException);

  return [
    total,
    total -
      (getWeeksCountBetween(start, now) -
        getExceptionsCountsBetweenDates(
          data.exceptions,
          start,
          now,
          noException
        )),
  ];
}

export function formatDate(date) {
  return moment.utc(date).format("YYYY-MM-DD");
}

export function getWeekDay(date) {
  return moment.utc(date).format("dddd").toLowerCase();
}

export function formatDateReadable(date) {
  const formattedDate = moment.utc(date).format("MMMM D, YYYY");
  const day = moment.utc(date).date();
  let suffix = "th";
  if (day % 10 === 1 && day !== 11) suffix = "st";
  else if (day % 10 === 2 && day !== 12) suffix = "nd";
  else if (day % 10 === 3 && day !== 13) suffix = "rd";
  return formattedDate.replace(/\b(\d{1,2})\b/, `$1${suffix}`);
}

export function convertToAmPm(time24hr) {
  return moment.utc(time24hr, "HH:mm").format("h:mma");
}

export function convertTimeToHHMMSS(timeStr) {
  const time = moment.utc(timeStr, "h:mma");
  return time.format("HH:mm:ss");
}

export function yearFilter(date) {
  const datetime = moment
    .utc(date * 1000)
    .year(moment.utc().year())
    .valueOf();
  const springStart = moment.utc(Tabs.spring.start).valueOf();
  const springEnd = moment.utc(Tabs.spring.end).valueOf();
  if (datetime >= springStart && datetime <= springEnd) return "spring-break";
  const summerStart = moment.utc(Tabs.summer.start).valueOf();
  const summerEnd = moment.utc(Tabs.summer.end).valueOf();
  if (datetime >= summerStart && datetime <= summerEnd) return "summer";
  const winterStart = moment.utc(Tabs.winter.start).valueOf();
  const winterEnd = moment.utc(Tabs.winter.end).valueOf();
  if (datetime >= winterStart && datetime <= winterEnd) return "winter";
  return "";
}

export function getStyleObject(classData) {
  const filter = yearFilter(classData.term_start);
  const classObject = {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right center",
  };
  if (filter === "spring-break") {
    classObject.backgroundColor = "#21fff0";
    classObject.backgroundImage = `url(${springImage})`;
  } else if (filter === "summer") {
    classObject.backgroundColor = "#ffe680";
    classObject.backgroundImage = `url(${summerImage})`;
  } else if (filter === "winter") {
    classObject.backgroundColor = "#dcfbff";
    classObject.backgroundImage = `url(${winterImage})`;
  } else {
    classObject.backgroundColor = "#dcfbff";
  }
  return classObject;
}
