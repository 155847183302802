import moment from "moment";
import { formatDate } from "./ClassTime";

export const Teachers = {
  Amrita: "#eeea00",
  Christine: "#d87093",
  Justine: "#70d875",
  Marion: "#eee8aa",
  Nathan: "#afeeee",
  Yvan: "#cdafee",
};

export const Grades = [
  "Booklet Elementary 1",
  "Booklet Elementary 2",
  "Booklet Elementary 3",
  "Booklet Advanced 1",
  "Booklet Advanced 2",
  "Booklet Advanced 3",
  "Conversation",
  "IB 10",
  "IB 11",
  "IB 12",
  "AP",
  "A1 Prim",
  "A2 Prim",
  "A1 Junior",
  "A2 Junior",
  "Adults",
  "B1",
  "B2",
  "C1",
  "K",
  "Pre-K",
  "TEF",
  "TCF",
];
export const Tabs = {
  winter: {
    title: "Winter",
    start: formatDate(moment.utc(`${moment.utc().year()}-12-20`)),
    end: formatDate(moment.utc(`${moment.utc().year() + 1}-03-17`)),
  },
  spring: {
    title: "Spring",
    start: formatDate(moment.utc(`${moment.utc().year()}-03-18`)),
    end: formatDate(moment.utc(`${moment.utc().year()}-06-19`)),
  },
  summer: {
    title: "Summer",
    start: formatDate(moment.utc(`${moment.utc().year()}-06-20`)),
    end: formatDate(moment.utc(`${moment.utc().year()}-09-08`)),
  },
  fall: {
    title: "Fall",
    start: formatDate(moment.utc(`${moment.utc().year()}-09-09`)),
    end: formatDate(moment.utc(`${moment.utc().year()}-12-16`)),
  },
  today: {
    title: "Today",
    start: formatDate(moment.utc()),
    end: formatDate(moment.utc()),
  },
  week: {
    title: "This Week",
    start: formatDate(moment.utc().startOf("isoWeek")),
    end: formatDate(moment.utc().endOf("isoWeek")),
  },
  month: {
    title: "This Month",
    start: formatDate(moment.utc().startOf("month")),
    end: formatDate(moment.utc().endOf("month")),
  },
};
