import { createContext } from "react";
const FilterContext = createContext({
  selectedDays: [],
  setSelectedDays: (data) => {},
  hiddenDays: [],
  setHiddenDays: (data) => {},
  isSidebarVisible: true,
  setIsSidebarVisible: (data) => {},
  searchQuery: "",
  setSearchQuery: (data) => {},
  teachers: [],
  setTeachers: (data) => {},
  grades: [],
  setGrades: (data) => {},
  dates: {
    start: null,
    end: null,
  },
  setDates: (data) => {},
  tab: null,
  setTab: (data) => {},
  hideEmptyDays: false,
  setHideEmptyDays: (data) => {},
  tableView: "classes",
  setTableView: (data) => {},
  parentFilters: {},
  setParentFilters: (data) => {},
});

export const FilterProvider = FilterContext.Provider;
export const FilterConsumer = FilterContext.Consumer;

export default FilterContext;
