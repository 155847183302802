import React, { useState } from "react";
import "./Schedule.scss";
import ScheduleHeader from "./ScheduleHeader";
import ScheduleClassesView from "./ScheduleClassesView";
import ScheduleSidebar from "./ScheduleSidebar";
import DataContext from "../contexts/DataContext";
import FilterContext from "../contexts/FilterContext";
import DetailsContext from "../contexts/DetailsContext";
import ScheduleClassRequest from "./ScheduleClassRequest";

export default function Schedule() {
  const [data, setData] = useState(null);
  const [details, setDetails] = useState(null);
  const [filter, setFilter] = useState([]);
  const [error, setError] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [hiddenDays, setHiddenDays] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [teachers, setTeachers] = useState([]);
  const [grades, setGrades] = useState([]);
  const [isAdminLogged, setIsAdminLogged] = useState(false);
  const [dates, setDates] = useState({});
  const [requestKey, setRequestKey] = useState(1);
  const [tab, setTab] = useState(null);
  const [hideEmptyDays, setHideEmptyDays] = useState(false);
  const [tableView, setTableView] = useState("");
  const [holidaysData, setHolidaysData] = useState([]);
  const [parentFilters, setParentFilters] = useState({});
  const [teacherList, setTeacherList] = useState([]);

  return (
    <div className="Schedule">
      <DataContext.Provider
        value={{
          data,
          setData,
          dataLoading,
          setDataLoading,
          requestKey,
          setRequestKey,
          holidaysData,
          setHolidaysData,
          teacherList,
          setTeacherList,
        }}
      >
        <FilterContext.Provider
          value={{
            filter,
            setFilter,
            selectedDays,
            setSelectedDays,
            hiddenDays,
            setHiddenDays,
            isSidebarVisible,
            setIsSidebarVisible,
            searchQuery,
            setSearchQuery,
            teachers,
            setTeachers,
            grades,
            setGrades,
            dates,
            setDates,
            tab,
            setTab,
            hideEmptyDays,
            setHideEmptyDays,
            tableView,
            setTableView,
            parentFilters,
            setParentFilters,
          }}
        >
          <DetailsContext.Provider
            value={{
              details,
              setDetails,
              isAdminLogged,
              setIsAdminLogged,
            }}
          >
            <ScheduleClassRequest setError={(value) => setError(value)} />
            <ScheduleHeader />
            <div className="ScheduleBody">
              <ScheduleSidebar />
              <ScheduleClassesView error={error} />
            </div>
          </DetailsContext.Provider>
        </FilterContext.Provider>
      </DataContext.Provider>
    </div>
  );
}
