import React, { useContext, useEffect, useState } from "react";
import FilterContext from "../contexts/FilterContext";
import DataContext from "../contexts/DataContext";

export default function ScheduleClassRequest(props) {
  const { teachers, grades, searchQuery, dates, tab, setParentFilters } =
    useContext(FilterContext);
  const { dataLoading, setDataLoading, requestKey, setData, setHolidaysData } =
    useContext(DataContext);
  const [debounceId, setDebounceId] = useState(null);
  const [isInit, setIsInit] = useState(false);

  const getFilterQuery = () => {
    let query = `?start_date=${dates.start}&end_date=${
      dates.end
    }&grades=${grades.join(",")}&teachers=${teachers.join(
      ","
    )}&query=${searchQuery}`;

    if (tab && !["month", "today", "week"].includes(tab)) {
      query += `&exclude_overlap=${true}`;
    }
    return query;
  };

  const getClasses = () => {
    setDataLoading(true);
    const url = `${
      process.env.REACT_APP_API_ENDPOINT
    }/schedule-classes${getFilterQuery()}`;

    fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      redirect: "follow",
      referrerPolicy: "no-referrer",
    })
      .then((response) => response.json())
      .then((rdata) => {
        setIsInit(true);
        setData(rdata.classes);
        setHolidaysData(rdata.holidays);
        setDataLoading(false);
      })
      .catch((err) => {
        setIsInit(true);
        console.error(err);
        props.setError(true);
        setDataLoading(false);
      });
    return () => {};
  };

  function sendMessageToParent(messageObj) {
    try {
      window.parent.postMessage({ ...messageObj, type: "set-filters" }, "*");
    } catch (e) {
      console.error(`Failed to update parent's url: ${e}`);
    }
  }

  useEffect(() => {
    clearTimeout(debounceId);
    setDebounceId(null);
    if (dataLoading) {
      return;
    }
    setDebounceId(
      setTimeout(() => {
        if (dates.start && dates.end) {
          getClasses();
        }
        if (isInit) {
          sendMessageToParent({ teachers, grades, searchQuery, dates, tab });
        }
      }, 1000)
    );
  }, [teachers, grades, searchQuery, dates, requestKey]);

  useEffect(() => {
    window.addEventListener("message", function (event) {
      if (event.data?.type === "get-filters") {
        const filters = event.data;
        setParentFilters(filters);
      }
    });
  }, []);
  return <></>;
}
