import React, { useState, useEffect, useContext } from "react";
import "./GradesFilter.scss";
import ExpandableSection from "./ExpandableSection";
import { Grades } from "../helpers/Constants";
import FilterContext from "../contexts/FilterContext";
import DataContext from "../contexts/DataContext";

export default function GradesFilter() {
  const [isExpanded, setIsExpanded] = useState(false);
  const { grades, setGrades, parentFilters } = useContext(FilterContext);
  const { dataLoading } = useContext(DataContext);
  const [isInit, setIsInit] = useState(false);

  const handleFilter = (grade) => {
    const newArr = [...grades];
    if (grades.includes(grade)) {
      const index = grades.indexOf(grade);
      newArr.splice(index, 1);
    } else {
      newArr.push(grade);
    }
    setGrades(newArr);
  };

  const setGradesUrl = (value = grades) => {
    const params = new URLSearchParams(window.location.search);
    params.set("grades", value.join(","));
    window.history.replaceState(null, null, "?" + params.toString());
  };

  const getGradesUrl = () => {
    const params = new URLSearchParams(window.location.search);
    const grades = params.get("grades");

    if (grades) {
      setGrades(grades.split(","));
      setIsExpanded(true);
    }
  };

  useEffect(() => {
    getGradesUrl();
  }, []);

  useEffect(() => {
    if (parentFilters.grades) {
      setGrades(parentFilters.grades);
      setIsExpanded(true);
    }
  }, [parentFilters]);

  useEffect(() => {
    if (isInit) {
      setGradesUrl();
    } else {
      setIsInit(true);
    }
  }, [grades]);

  return (
    <ExpandableSection title="Grades" isExpanded={isExpanded}>
      <ul className="GradesFilterList">
        {Grades.map((grade) => {
          return (
            <li key={grade} className="TeacherListItem">
              <label>
                <input
                  type="checkbox"
                  value={grade}
                  checked={grades.includes(grade)}
                  name="teacher"
                  onChange={() => handleFilter(grade)}
                  disabled={dataLoading}
                />
                <span data-teacher={grade}>{grade}</span>
              </label>
            </li>
          );
        })}
      </ul>
    </ExpandableSection>
  );
}
