import React, { useContext, useEffect, useState } from "react";
import ExpandableSection from "./ExpandableSection";
import "./TeachersFilter.scss";
import FilterContext from "../contexts/FilterContext";
import DataContext from "../contexts/DataContext";
import DetailsContext from "../contexts/DetailsContext";
import { FaSpinner, FaTrash } from "react-icons/fa";
import ScheduleAlert from "./ScheduleAlert";

export default function TeachersFilter() {
  const { teachers, setTeachers, parentFilters } = useContext(FilterContext);
  const { dataLoading, teacherList, setTeacherList } = useContext(DataContext);
  const { isAdminLogged } = useContext(DetailsContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const [teachersLoading, setTeachersLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form, setForm] = useState({
    name: "",
    color: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    color: "",
  });
  const [savingTeacher, setSavingTeacher] = useState(false);
  const [mainError, setMainError] = useState("");
  const [deleteError, setDeleteError] = useState("");
  const [deletingId, setDeletingId] = useState(0);

  const handleFilter = (teacher) => {
    let newArr = [...teachers];

    if (teachers.includes(teacher)) {
      const index = newArr.indexOf(teacher);
      newArr.splice(index, 1);
    } else {
      newArr.push(teacher);
    }

    setTeachers(newArr);
  };

  const setTeachersUrl = (value = teachers) => {
    const params = new URLSearchParams(window.location.search);
    params.set("teachers", value.join(","));
    window.history.replaceState(null, null, "?" + params.toString());
  };

  const getTeachersUrl = () => {
    const params = new URLSearchParams(window.location.search);
    const teachers = params.get("teachers");

    if (teachers) {
      setTeachers(teachers.split(","));
      setIsExpanded(true);
    }
  };

  const getTeachers = () => {
    setTeachersLoading(true);
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/teachers`, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      redirect: "follow",
      referrerPolicy: "no-referrer",
    })
      .then((response) => response.json())
      .then((rdata) => {
        setTeachersLoading(false);
        if (rdata.success) {
          setTeacherList(
            rdata.teachers.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
          );
        }
      })
      .catch((err) => {
        console.error(err);
        setTeachersLoading(false);
      });
  };

  const handleModalOpen = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const handleClickOutside = (e) => {
    const id = e.target.id;
    if (id === "TeachersModal") {
      setIsModalOpen(false);
    }
  };

  const handleFormInputs = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const saveTeacher = () => {
    if (savingTeacher) return;
    setErrors({});
    const errors = {};
    if (!form.color) {
      errors.color = "Color is required";
    }
    if (!form.name) {
      errors.name = "Teacher name is required";
    }
    setErrors(errors);
    if (form.color && form.name) {
      setSavingTeacher(true);
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/teacher/create`, {
        method: "POST",
        body: JSON.stringify(form),
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        redirect: "follow",
        referrerPolicy: "no-referrer",
      })
        .then((response) => response.json())
        .then((r) => {
          setSavingTeacher(false);
          if (r.errors) {
            setErrors(r.errors);
          } else if (r.main_error) {
            setMainError(r.main_error);
          } else {
            getTeachers();
            setIsModalOpen(false);
          }
        })
        .catch((e) => {
          setSavingTeacher(false);
          console.error(e);
        });
    }
  };

  const deleteTeacher = (e, id) => {
    e.stopPropagation();
    if (deletingId) return;
    setDeleteError("");
    if (window.confirm("Delete teacher?")) {
      setDeletingId(id);
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/teacher/delete/${id}`, {
        method: "DELETE",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        redirect: "follow",
        referrerPolicy: "no-referrer",
      })
        .then((response) => response.json())
        .then((r) => {
          setDeletingId(0);
          if (r.success) {
            getTeachers();
          } else {
            setDeleteError(r.message);
          }
        })
        .catch((e) => {
          console.error(e);
          setDeleteError("Failed to delete teacher");
          setDeletingId(0);
        });
    }
  };

  useEffect(() => {
    getTeachersUrl();
    getTeachers();
  }, []);

  useEffect(() => {
    if (parentFilters.teachers) {
      setTeachers(parentFilters.teachers);
      setIsExpanded(true);
    }
  }, [parentFilters]);

  useEffect(() => {
    if (isInit) {
      setTeachersUrl();
    } else {
      setIsInit(true);
    }
  }, [teachers]);

  return (
    <div>
      <ExpandableSection
        title="Teachers"
        isExpanded={isExpanded}
        action={isAdminLogged ? (e) => handleModalOpen(e) : null}
        loading={teachersLoading}
      >
        <ul className="FilterList">
          {teacherList.map((t) => {
            return (
              <li
                key={t.id}
                className="TeacherListItem"
                style={{
                  background: t.color,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label>
                  <input
                    type="checkbox"
                    value={t}
                    checked={teachers.includes(t.name)}
                    name="teacher"
                    onChange={() => handleFilter(t.name)}
                    disabled={dataLoading}
                  />
                  <span data-teacher={t.name}>{t.name}</span>
                </label>
                {isAdminLogged ? (
                  <div
                    onClick={(e) => {
                      deleteTeacher(e, t.id);
                    }}
                  >
                    {deletingId === t.id ? (
                      <FaSpinner className="spinner-loader" />
                    ) : (
                      <FaTrash className="ItemDeleteIcon" />
                    )}
                  </div>
                ) : (
                  ""
                )}
              </li>
            );
          })}
        </ul>
      </ExpandableSection>
      {isModalOpen ? (
        <div
          className="ScheduleModal"
          id="TeachersModal"
          onClick={(e) => handleClickOutside(e)}
        >
          <div className="ScheduleModalContent">
            <div className="ScheduleModalHead">Add new teacher</div>
            <div className="ScheduleModalBody">
              <div className="ScheduleModalForm">
                <div className="ScheduleModalInputs">
                  <div className="ScheduleModalInput">
                    <label htmlFor="teacherName">Name</label>
                    <input
                      id="teacherName"
                      name="name"
                      placeholder="Enter teacher's name"
                      value={form.name}
                      onChange={handleFormInputs}
                    />
                    {errors.name ? (
                      <small className="ScheduleModalError">
                        {errors.name}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="ScheduleModalInput">
                    <label htmlFor="teacherColor">Color</label>
                    <input
                      id="teacherColor"
                      name="color"
                      type="color"
                      value={form.color}
                      onChange={handleFormInputs}
                    />
                    {errors.color ? (
                      <small className="ScheduleModalError">
                        {errors.color}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="ScheduleModalFoot">
              <small className="ScheduleModalError">{mainError}</small>
              <div className="ScheduleModalActions">
                <button onClick={() => setIsModalOpen(false)}>Cancel</button>
                <button
                  className="ConfirmActionButton"
                  onClick={() => saveTeacher()}
                  disabled={teachersLoading}
                >
                  {savingTeacher ? (
                    <FaSpinner className="spinner-loader" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {deleteError ? <ScheduleAlert type="error" message={deleteError} /> : ""}
    </div>
  );
}
