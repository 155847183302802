import React, { useContext, useState } from "react";
import "./ScheduleTable.scss";
import DataContext from "../contexts/DataContext";
import {
  FaSquare,
  FaCheckSquare,
  FaTrash,
  FaEdit,
  FaSpinner,
} from "react-icons/fa";
import {
  convertTimeToHHMMSS,
  formatDate,
  formatDateReadable,
} from "../helpers/ClassTime";
import ScheduleClassDetails from "./ScheduleClassDetails";
import DetailsContext from "../contexts/DetailsContext";
import ScheduleAlert from "./ScheduleAlert";
import ScheduleClassManager from "./ScheduleClassManager";
import moment from "moment";

export default function ScheduleTable() {
  const { data, setRequestKey, requestKey, dataLoading, teacherList } =
    useContext(DataContext);
  const { setDetails, details, isAdminLogged } = useContext(DetailsContext);
  const [response, setResponse] = useState({});
  const [deleting, setDeleting] = useState(0);
  const [editRow, setEditRow] = useState({});

  const reloadClasses = () => {
    setTimeout(() => {
      setRequestKey(requestKey + 1);
    }, 2000);
  };

  const deleteClass = (e, id) => {
    e.stopPropagation();
    if (deleting) return;

    if (window.confirm("Delete class?")) {
      setDeleting(id);
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/schedule-classes/delete/${id}`,
        {
          method: "DELETE",
          mode: "cors",
          cache: "no-cache",
          credentials: "include",
          redirect: "follow",
          referrerPolicy: "no-referrer",
        }
      )
        .then((response) => response.json())
        .then((r) => {
          setDeleting(0);
          if (r?.status !== "success") {
            setResponse({
              type: "error",
              message: "Failed to delete holiday.",
            });
          } else {
            setResponse(r);
            reloadClasses();
          }
        })
        .catch((e) => {
          setDeleting(0);
          setResponse({
            status: "error",
            message: "Failed to delete",
          });
          reloadClasses();
          console.error(e);
        });
    }
  };

  const updateClass = (e, row) => {
    e.stopPropagation();

    setEditRow({
      id: row.id,
      title: row.title,
      grades: row.grades.split(" - "),
      teacher: row.teacher,
      term_start_date: formatDate(moment(row.term_start * 1000)),
      term_end_date: formatDate(moment(row.term_end * 1000)),
      class_start_time: convertTimeToHHMMSS(row.start),
      class_end_time: convertTimeToHHMMSS(row.end),
      hourly_rate: row.hourly_rate,
      is_full: row.class_full,
      is_almost_full: row.class_almost_full,
      is_multiclass: row.multiclass,
      is_online: row.online,
      is_onsite: row.onsite,
    });
  };

  const classStyleObject = (list) => {
    const teachers = list.split(" ");
    const colors = [];
    for (let teacher of teachers) {
      const teacherData = teacherList.filter((t) => t.name === teacher)[0];
      if (teacherData) {
        colors.push(teacherData.color);
      }
    }
    if (colors.length) {
      if (colors.length > 1) {
        return {
          background: `linear-gradient(135deg, ${colors.join(", ")})`,
        };
      } else {
        return {
          background: colors[0],
        };
      }
    }
    return {};
  };

  return (
    <div className="ScheduleTable">
      {dataLoading ? (
        <FaSpinner className="spinner-loader" />
      ) : (
        <table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Teacher</th>
              <th>Hourly Rate</th>
              <th>Grades</th>
              <th>Start date</th>
              <th>End date</th>
              <th>Class start time</th>
              <th>Class end time</th>
              <th>Multiclass</th>
              <th>Online</th>
              <th>Onsite</th>
              <th>Class full</th>
              <th>Class almost full</th>
              {isAdminLogged ? <th>Actions</th> : ""}
            </tr>
          </thead>
          <tbody>
            {data.map((row) => {
              return (
                <tr
                  className="ScheduleTableRows"
                  key={row.id}
                  style={classStyleObject(row.teacher)}
                  onClick={() => setDetails(row)}
                >
                  <td>{row.title}</td>
                  <td>
                    {teacherList.map((t) => {
                      if (row.teacher.includes(t.name)) {
                        return <div key={t.id}>{"- " + t.name}</div>;
                      }
                    })}
                  </td>
                  <td>{row.hourly_rate}</td>
                  <td>
                    {row.grades.split("-").map((grade) => {
                      return <div key={row.id + "-" + grade}>- {grade}</div>;
                    })}
                  </td>
                  <td>{formatDateReadable(moment(row.term_start * 1000))}</td>
                  <td>{formatDateReadable(moment(row.term_end * 1000))}</td>
                  <td>{row.start}</td>
                  <td>{row.end}</td>
                  <td>{row.multiclass ? <FaCheckSquare /> : <FaSquare />}</td>
                  <td>{row.online ? <FaCheckSquare /> : <FaSquare />}</td>
                  <td>{row.onsite ? <FaCheckSquare /> : <FaSquare />}</td>
                  <td>{row.class_full ? <FaCheckSquare /> : <FaSquare />}</td>
                  <td>
                    {row.class_almost_full ? <FaCheckSquare /> : <FaSquare />}
                  </td>
                  {isAdminLogged ? (
                    <td>
                      <div className="ScheduleTableActions">
                        <button
                          className="ScheduleTableBtn"
                          onClick={(e) => deleteClass(e, row.id)}
                          disabled={deleting}
                        >
                          {deleting === row.id ? (
                            <FaSpinner className="spinner-loader" />
                          ) : (
                            <FaTrash className="ScheduleTableBtnIcon" />
                          )}
                        </button>
                        <button
                          className="ScheduleTableBtn"
                          onClick={(e) => updateClass(e, row)}
                          disabled={deleting}
                        >
                          <FaEdit className="ScheduleTableBtnIcon" />
                        </button>
                      </div>
                    </td>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {details ? <ScheduleClassDetails data={details} /> : ""}
      {response.status ? (
        <ScheduleAlert type={response.status} message={response.message} />
      ) : (
        ""
      )}

      <ScheduleClassManager
        manualTrigger={editRow.id ? true : false}
        editClass={editRow.id ? editRow : {}}
        close={() => setEditRow({})}
      />
    </div>
  );
}
