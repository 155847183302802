import React, { useContext } from "react";
import { getOverlapData } from "../helpers/ClassTime";
import { firstLetterToUpperCase } from "../helpers/String";
import ScheduleClass from "./ScheduleClass";
import "./ScheduleDay.scss";
import { getWeekDay, formatDateReadable } from "../helpers/ClassTime";
import moment from "moment";

export default function ScheduleDay(props) {
  const timeSpan = props.timeSpan;
  const data = props.data;
  const dateInstance = moment.utc(props.date);
  const dateString = formatDateReadable(dateInstance);
  const weekday = getWeekDay(dateInstance);
  const overlapData = data ? getOverlapData(data, timeSpan, weekday) : null;

  return (
    <div className="ScheduleDay">
      <h2 className="ScheduleDate">
        {firstLetterToUpperCase(weekday)}, {dateString}
      </h2>

      <div className="day">
        {data ? (
          data.map((d, i) => {
            return (
              <ScheduleClass
                key={i}
                num={i}
                disposition={overlapData[i]}
                data={d}
                division={(timeSpan.max - timeSpan.min) * 12}
                timeSpan={timeSpan}
              />
            );
          })
        ) : (
          <div style={{ textAlign: "center", marginTop: "0.3em" }}>
            No class
          </div>
        )}
      </div>
    </div>
  );
}
