import React, { useEffect, useState } from "react";
import "./ScheduleAlert.scss";

export default function ScheduleAlert(props) {
  const [top, setTop] = useState("-20em");

  const STYLES = {
    default: {
      backgroundColor: "#f1f1f1",
      border: "1px solid #d5d5d5",
    },
    error: {
      backgroundColor: "#E57373",
      border: "1px solid #FFEBEE",
      color: "#fff",
    },
    success: {
      backgroundColor: "#00C853",
      border: "1px solid #C8E6C9",
      color: "#fff",
    },
  };

  useEffect(() => {
    setTop("3em");
    setTimeout(() => {
      setTop("-20em");
    }, 3000);
    return;
  }, [props]);

  useEffect(() => {
    return () => {
      setTop("");
    };
  }, []);

  return (
    <div
      className="ScheduleAlert"
      style={{ ...STYLES[props.type || "default"], top }}
    >
      {props.message || "My alert"}
    </div>
  );
}
