import React, { useContext, useEffect, useState } from "react";
import "./ScheduleSidebar.scss";
import { FaFilter, FaTable, FaBook, FaTree, FaCalendar } from "react-icons/fa";
import FilterContext from "../contexts/FilterContext";
import TeachersFilter from "./TeachersFilter";
import GradesFilter from "./GradesFilter";
import DetailsContext from "../contexts/DetailsContext";
import ScheduleClassManager from "./ScheduleClassManager";
import ScheduleDatePicker from "./ScheduleDatePicker";

export default function ScheduleSidebar(props) {
  const {
    isSidebarVisible,
    searchQuery,
    setSearchQuery,
    tableView,
    setTableView,
    parentFilters,
  } = useContext(FilterContext);
  const { isAdminLogged } = useContext(DetailsContext);
  const [isInit, setIsInit] = useState(false);

  const setSearchUrl = (value = searchQuery) => {
    const params = new URLSearchParams(window.location.search);
    params.set("query", value);
    window.history.replaceState(null, null, "?" + params.toString());
  };

  const getSearchUrl = () => {
    const params = new URLSearchParams(window.location.search);
    const query = params.get("query");
    if (query) {
      setSearchQuery(query);
    }
  };

  const handleSearchQuery = (query) => {
    setSearchQuery(query);
  };

  useEffect(() => {
    getSearchUrl();
  }, []);

  useEffect(() => {
    if (parentFilters.searchQuery) {
      setSearchQuery(parentFilters.searchQuery);
    }
  }, [parentFilters]);

  useEffect(() => {
    if (isInit) {
      setSearchUrl();
    } else {
      setIsInit(true);
    }
  }, [searchQuery, isInit]);

  return (
    <div
      className="ScheduleSidebar"
      style={{
        width: isSidebarVisible ? "20%" : "0%",
        padding: isSidebarVisible ? "20px 25px" : "0px",
      }}
    >
      <>
        {isSidebarVisible ? (
          <div className="ScheduleSidebarContent">
            {isAdminLogged ? <ScheduleClassManager /> : ""}
            <div className="SectionHeading">
              <FaFilter /> Filters
            </div>
            <ScheduleDatePicker />
            <input
              className="SearchBar"
              type="text"
              placeholder="Search for classes"
              value={searchQuery}
              onInput={(e) => handleSearchQuery(e.target.value)}
            ></input>
            <TeachersFilter />
            <GradesFilter />

            <div className="SectionHeading">
              <FaTable /> View
            </div>
            <div className="DetailedViewButtons">
              <button
                onClick={() => setTableView("")}
                style={{
                  background: !tableView ? "#ffc114" : "white",
                  color: !tableView ? "white" : "black",
                }}
              >
                <FaCalendar />
                Calendar
              </button>
              <button
                onClick={() => setTableView("classes")}
                style={{
                  background: tableView === "classes" ? "#ffc114" : "white",
                  color: tableView === "classes" ? "white" : "black",
                }}
              >
                <FaBook />
                Classes
              </button>
              <button
                onClick={() => setTableView("holidays")}
                style={{
                  background: tableView === "holidays" ? "#ffc114" : "white",
                  color: tableView === "holidays" ? "white" : "black",
                }}
              >
                <FaTree />
                Holidays
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    </div>
  );
}
