import React, { useEffect, useState } from "react";
import "./ExpandableSection.scss";
import { FaPlus, FaChevronDown, FaChevronUp, FaSpinner } from "react-icons/fa";

export default function ExpandableSection(props) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleAction = (e) => {
    props.action(e);
  };

  const handleCollapseExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (props.isExpanded) {
      setIsExpanded(true);
    }
  }, [props.isExpanded]);

  useEffect(() => {
    if (isExpanded && props.expandAction) {
      props.expandAction();
    }
  }, [isExpanded]);

  return (
    <div className="Collapsible">
      <div className="CollapseBar" onClick={() => handleCollapseExpand()}>
        {props.title}
        <div className="ActionButton">
          <span className="CollapsePlus">
            {props.action ? <FaPlus onClick={(e) => handleAction(e)} /> : ""}
          </span>
          <span className="CollapseArrow">
            {!props.loading ? (
              isExpanded ? (
                <FaChevronDown />
              ) : (
                <FaChevronUp />
              )
            ) : (
              <FaSpinner className="spinner-loader" />
            )}
          </span>
        </div>
      </div>
      <div
        style={{
          transition: isExpanded
            ? "max-height 0.25s ease-in"
            : "max-height 0.25s ease-out",
          maxHeight: isExpanded ? "400px" : "0px",
        }}
        className="CollapseDropdown"
      >
        {props.children}
      </div>
    </div>
  );
}
