import React, { useEffect, useState } from "react";
import "./ScheduleDropdown.scss";
import {
  FaCheckSquare,
  FaChevronDown,
  FaChevronUp,
  FaSquare,
} from "react-icons/fa";

export default function ScheduleDropdown(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [uniqueId, setUniqueId] = useState("");

  const HandleLabel = () => {
    if (selected.length) {
      return (
        <div>
          {selected.slice(0, 3).join(", ")}{" "}
          {selected.length > 3 && (
            <span className="ScheduleDropdownBoldText">
              {"+" + (selected.length - 3)}
            </span>
          )}
        </div>
      );
    } else if (props.label) {
      return <div>{props.label}</div>;
    } else {
      return <div>Select</div>;
    }
  };

  const handleSelected = (item) => {
    if (props.isMultiple) {
      handleMultiple(item);
    } else {
      handleSingle(item);
    }
  };

  const handleMultiple = (item) => {
    const selectedItems = [...selected];
    const index = selectedItems.indexOf(item);
    if (index > -1) {
      selectedItems.splice(index, 1);
    } else {
      selectedItems.push(item);
    }
    setSelected(selectedItems);
    if (props.onChange) {
      props.onChange(item);
    }
  };

  const handleSingle = (item) => {
    setSelected([item]);
    setIsOpen(false);
    if (props.onChange) {
      props.onChange(item);
    }
  };

  const handleClickOutside = (event) => {
    let myDiv = document.getElementById(uniqueId);
    if (myDiv) {
      let isClickInside = myDiv.contains(event.target);

      if (!isClickInside) {
        document.removeEventListener("click", handleClickOutside);
        setIsOpen(false);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      props.defaultSelected &&
      props.defaultSelected.length &&
      !selected.length
    ) {
      setSelected([...props.defaultSelected]);
    }
  }, [props.defaultSelected]);

  useEffect(() => {
    setUniqueId(Math.random(0, 999) + "-ScheduleDropdownContainer");
  }, []);

  return (
    <div className="ScheduleDropdownContainer" id={uniqueId}>
      <div
        className="ScheduleDropdownTrigger"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="ScheduleDropdownLabel">
          <HandleLabel />
        </div>
        {isOpen ? <FaChevronDown /> : <FaChevronUp />}
      </div>
      {isOpen && (
        <div className="ScheduleDropdown">
          <div className="ScheduleDropdownSingleSelector ScheduleDropdownItems">
            {(props.items || []).map((item, index) => {
              return (
                <div
                  onClick={() => handleSelected(item)}
                  key={`${item}-${index}`}
                  className="ScheduleDropdownItem"
                >
                  {item}
                  {props.isMultiple &&
                    (selected.indexOf(item) > -1 ? (
                      <FaCheckSquare />
                    ) : (
                      <FaSquare />
                    ))}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
