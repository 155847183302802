import React, { useContext, useEffect, useState } from "react";
import "./ScheduleHeader.scss";
import { FaBars } from "react-icons/fa";
import FilterContext from "../contexts/FilterContext";
import ScheduleLoginAction from "./ScheduleLoginAction";
import { Tabs } from "../helpers/Constants";
import DataContext from "../contexts/DataContext";

export default function ScheduleHeader() {
  const {
    isSidebarVisible,
    setIsSidebarVisible,
    setDates,
    dates,
    setTab,
    tab,
    tableView,
    setTableView,
    parentFilters,
  } = useContext(FilterContext);

  const { dataLoading } = useContext(DataContext);

  const [isInit, setIsInit] = useState(false);

  const handleDates = (tabKey) => {
    if (dataLoading) return;
    const selectedTab = Tabs[tabKey];
    const newDates = { ...dates };
    newDates.start = selectedTab.start;
    newDates.end = selectedTab.end;
    setDates(newDates);
    setTab(tabKey);
  };

  const setTabUrl = () => {
    const params = new URLSearchParams(window.location.search);
    if (tab) {
      params.set("tab", tab);
    } else {
      params.delete("tab");
    }
    window.history.replaceState(null, null, "?" + params.toString());
  };

  const getTabUrl = () => {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get("tab");
    if (tab) {
      setTab(tab);
    }
  };

  const getTableViewUrl = () => {
    const params = new URLSearchParams(window.location.search);
    const view = params.get("tableView");
    if (view) {
      setTableView(view);
    }
  };

  const setTableViewUrl = () => {
    const params = new URLSearchParams(window.location.search);
    if (tableView) {
      params.set("tableView", tableView);
    } else {
      params.delete("tableView");
    }
    window.history.replaceState(null, null, "?" + params.toString());
  };

  useEffect(() => {
    if (isInit) {
      setTabUrl();
    } else {
      setIsInit(true);
    }
  }, [tab]);

  useEffect(() => {
    if (isInit) {
      setTableViewUrl();
    }
  }, [tableView]);

  useEffect(() => {
    if (parentFilters.tab) {
      setTab(parentFilters.tab);
    }
  }, [parentFilters]);

  useEffect(() => {
    getTabUrl();
    getTableViewUrl();
  }, []);

  return (
    <div className="ScheduleHeader">
      <h1>
        <FaBars
          style={{ marginRight: "20px", cursor: "pointer", fontSize: "20px" }}
          onClick={() => setIsSidebarVisible(!isSidebarVisible)}
        />
        <img
          src="https://waeelearning.com/images/doodles/temp_calendar.png"
          alt=""
        />{" "}
        <span>Class schedule</span>
      </h1>
      <div className="tabs">
        {Object.keys(Tabs).map((t) => {
          return (
            <button
              className={t === tab ? "active" : ""}
              key={t}
              onClick={() => handleDates(t)}
              disabled={dataLoading}
            >
              {Tabs[t].title}
            </button>
          );
        })}
      </div>
      <div className="ScheduleHeaderActions">
        <ScheduleLoginAction />
      </div>
    </div>
  );
}
