import React, { useCallback, useContext, useEffect, useRef } from "react";
import DataContext from "../contexts/DataContext";
import DetailsContext from "../contexts/DetailsContext";
import {
  getDateSuffix,
  getDuration,
  getStyleObject,
  getWeeksCounts,
  timeToDuration,
  timeToFloat,
  yearFilter,
} from "../helpers/ClassTime";
import { firstLetterToUpperCase } from "../helpers/String";
import "./ScheduleClassDetails.scss";

import onsite_icon from "../icons/onsite.svg";
import online_icon from "../icons/online.svg";
import moment from "moment";

export default function ScheduleClassDetails(props) {
  // eslint-disable-next-line no-unused-vars

  const { setDetails } = useContext(DetailsContext);
  const { data, teacherList } = useContext(DataContext);
  const ref = useRef(null);

  const handleClickOutside = useCallback(
    (e) => {
      if (ref && ref.current && !ref.current.contains(e.target)) {
        setDetails(null);
      }
    },
    [setDetails]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const classData = props.data;
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const start = moment.utc(classData.term_start * 1000);
  const end = moment.utc(classData.term_end * 1000);
  let [totalClassCount, leftClassCount] = getWeeksCounts(
    classData,
    classData.teacher.includes("(SB)")
  );
  let relatedClasses = null;

  if (classData.multiclass) {
    relatedClasses = data.filter(
      (c) =>
        c.title === classData.title &&
        c.start === classData.start &&
        c.end === classData.end &&
        c.term_start === classData.term_start &&
        c.term_end === classData.term_end &&
        c.id !== classData.id
    );

    relatedClasses.forEach((c) => {
      const [addToTotal, addToLeftClass] = getWeeksCounts(
        c,
        c.teacher.includes("(SB)")
      );
      totalClassCount += addToTotal;
      leftClassCount += addToLeftClass;
    });
  }
  if (leftClassCount < 0) leftClassCount = 0;

  const compareClasses = (a, b) => {
    const week = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];

    if (week.indexOf(a.day) < week.indexOf(b.day)) {
      return -1;
    } else if (week.indexOf(a.day) > week.indexOf(b.day)) {
      return 1;
    } else {
      const timeDiff = timeToFloat(a.start) - timeToFloat(b.start);
      if (timeDiff < 0) return -1;
      else if (timeDiff > 0) return 1;
    }
    return 0;
  };

  const duration = getDuration(classData.start, classData.end);

  return (
    <div className="ScheduleClassDetails">
      <article ref={ref} className="details" style={getStyleObject(classData)}>
        <header>
          <h2 className="title">
            {classData.title}
            {classData.class_full ? (
              <div className="alert">
                <div>
                  <img
                    src="https://waeelearning.com/images/doodles/lock_closed.png"
                    alt=""
                  />
                  Full
                </div>
              </div>
            ) : (
              ""
            )}
            {classData.class_almost_full ? (
              <div className="alert">
                <div>
                  <img
                    src="https://waeelearning.com/images/doodles/warning.png"
                    alt=""
                  />
                  Last spot
                </div>
              </div>
            ) : (
              ""
            )}
          </h2>
          <button
            onClick={() => {
              setDetails(null);
            }}
          >
            &#10799;
          </button>
        </header>
        <main>
          <div className="schedule">
            <img
              src="https://waeelearning.com/images/doodles/temp_calendar.png"
              alt="Schedule:"
            />
            {classData.multiclass &&
            relatedClasses &&
            relatedClasses.length > 0 ? (
              relatedClasses
                .concat([classData])
                .every(
                  (val, i, arr) =>
                    val.start === arr[0].start && val.end === arr[0].end
                ) ? (
                //Every classes have the same start and end, group them
                <div className="grouped">
                  <div>
                    {relatedClasses
                      .concat([classData])
                      .map((c) => firstLetterToUpperCase(c.day))
                      .join(", ")}
                  </div>
                  <div>
                    {classData.start} to {classData.end} (
                    {timeToDuration(
                      getDuration(classData.start, classData.end)
                    )}
                    )
                  </div>
                </div>
              ) : (
                <ul className="related">
                  {relatedClasses
                    .concat([classData])
                    .sort(compareClasses)
                    .map((c) => {
                      return (
                        <li key={c.id}>
                          {firstLetterToUpperCase(c.day)} - {c.start} to {c.end}{" "}
                          ({timeToDuration(getDuration(c.start, c.end))})
                        </li>
                      );
                    })}
                </ul>
              )
            ) : (
              <>
                {firstLetterToUpperCase(classData.day)} - {classData.start} to{" "}
                {classData.end} ({timeToDuration(duration)})
              </>
            )}
          </div>
          <div className="teacher">
            <img
              src="https://waeelearning.com/images/doodles/crayon.png"
              alt=""
            />{" "}
            With {classData.teacher.split(" ").join(" & ")}
          </div>
          <div className="online-onsite">
            {classData.online ? <img src={online_icon} alt="" /> : undefined}
            {classData.onsite ? <img src={onsite_icon} alt="" /> : undefined}
            {classData.online ? "Online" : undefined}
            {classData.onsite ? (
              <>{classData.online ? " & " : undefined} Onsite</>
            ) : undefined}
          </div>
          <div className="term">
            <div className="info">
              <div className="box start">
                <div className="from-to">From</div>
                <div className="date">
                  <span>{months[start.month()]}</span>
                  <span>
                    <span className="suffix">{getDateSuffix(start)}</span>
                  </span>
                </div>
              </div>
              <div className="box end">
                <div className="from-to">To</div>
                <div className="date">
                  <span>{months[end.month()]}</span>
                  <span>
                    <span className="suffix">{getDateSuffix(end)}</span>
                  </span>
                </div>
              </div>
              <div className="box count">
                <div className="count">{leftClassCount}</div>
                <div className="text">classes left</div>
                <div className="total">({totalClassCount} total)</div>
              </div>
            </div>
          </div>
          {classData.hourly_rate > 0 ? (
            <div className="price">
              <div className="hourly-rate">
                <span className="title">Hourly rate:</span>
                <span className="amount">
                  ${classData.hourly_rate.toFixed(2)}
                </span>
                <span className="tax-inc">tax exc.</span>
              </div>
              <div className="total">
                <span className="title">Total:</span>
                <span className="amount">
                  $
                  {(leftClassCount * duration * classData.hourly_rate).toFixed(
                    2
                  )}
                </span>
                <span className="tax-inc">tax exc.</span>
              </div>
            </div>
          ) : undefined}
          {classData.exceptions.length > 0 &&
          !classData.teacher.includes("(SB)") ? (
            <div className="exceptions">
              <div>No class on:</div>
              <ul>
                {classData.exceptions.map((e, i) => {
                  const date = moment.utc(e.date);
                  return (
                    <li key={i}>
                      {`${date.year()}-${date.month() + 1 < 10 ? 0 : ""}${
                        date.month() + 1
                      }-${date.date() < 10 ? 0 : ""}${date.date()}`}
                      : {e.name} - {e.description}
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            ""
          )}
        </main>
      </article>
    </div>
  );
}
