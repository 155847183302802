import React, { useContext, useEffect, useState } from "react";
import { FaSpinner, FaUser, FaSignOutAlt } from "react-icons/fa";
import DetailsContext from "../contexts/DetailsContext";
import "./ScheduleLoginAction.scss";

export default function ScheduleLoginAction() {
  const [isLoading, setIsLoading] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [hasLoginFailed, setHasLoginFailed] = useState(false);
  const [form, setForm] = useState({
    username: "",
    password: "",
  });
  const { isAdminLogged, setIsAdminLogged } = useContext(DetailsContext);

  const handleFormInputs = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const login = () => {
    if (form.username && form.password) {
      setIsLoading(true);
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/login`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({ ...form }),
      })
        .then((response) => {
          for (let pair of response.headers.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
          }
          return response.json();
        })
        .then((data) => {
          setIsLoading(false);
          if (data.user && data.token && data.is_admin) {
            setHasLoginFailed(false);
            setIsAdminLogged(true);
            setShowLogin(false);
          } else {
            setHasLoginFailed(true);
          }
        })
        .catch((e) => {
          setIsLoading(false);
          console.error(e);
          setHasLoginFailed(true);
        });
    } else {
      setShowValidation(true);
    }
  };

  const logout = () => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/logout`, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    })
      .then((response) => {
        console.log(response);
        return response.json();
      })
      .then((data) => {
        setIsLoading(false);
        if (data.status === "success") {
          setIsAdminLogged(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const checkTokenStatus = () => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/checkToken`, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    })
      .then((response) => response.json())
      .then((r) => {
        setIsLoading(false);
        if (r.is_auth_valid) {
          setIsAdminLogged(true);
        } else {
          setIsAdminLogged(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        setIsAdminLogged(false);
      });
  };

  useEffect(() => {
    checkTokenStatus();
  }, []);

  const handleClickOutside = (e) => {
    const id = e.target.id;
    if (id === "ScheduleLoginModal") {
      setShowLogin(false);
    }
  };

  return (
    <div className="ScheduleLoginAction">
      {!isAdminLogged ? (
        <button onClick={() => setShowLogin(!showLogin)}>
          Admin Login
          {isLoading ? <FaSpinner className="spinner-loader" /> : <FaUser />}
        </button>
      ) : (
        <button onClick={() => logout()}>
          Logout{" "}
          {isLoading ? (
            <FaSpinner className="spinner-loader" />
          ) : (
            <FaSignOutAlt />
          )}
        </button>
      )}

      {showLogin ? (
        <div
          className="ScheduleModal"
          id="ScheduleLoginModal"
          onClick={(e) => handleClickOutside(e)}
        >
          <div className="ScheduleModalContent">
            <div className="ScheduleModalHead">Admin Login</div>
            <div className="ScheduleModalBody">
              <div className="ScheduleForm" id="ScheduleLoginForm">
                <div className="ScheduleFormInput">
                  <label htmlFor="username">Username/Email</label>
                  <input
                    id="username"
                    name="username"
                    placeholder="Enter username or email"
                    value={form.username}
                    onChange={handleFormInputs}
                  />
                  <small className="ScheduleFormError">
                    {showValidation && !form.username
                      ? "Please enter your username/email"
                      : ""}
                  </small>
                </div>
                <div className="ScheduleFormInput">
                  <label htmlFor="password">Password</label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Enter password"
                    value={form.password}
                    onChange={handleFormInputs}
                  />
                  <small className="ScheduleFormError">
                    {showValidation && !form.password
                      ? "Please enter your password"
                      : ""}
                  </small>
                </div>
              </div>
            </div>
            <div className="ScheduleModalFoot">
              <small className="ScheduleFormError">
                {hasLoginFailed ? "Failed to login" : ""}
              </small>
              <div className="ScheduleModalActions">
                <button
                  disabled={isLoading}
                  onClick={() => setShowLogin(false)}
                >
                  Close
                </button>
                <button
                  className="ConfirmActionButton"
                  disabled={isLoading}
                  onClick={() => login()}
                >
                  Login{" "}
                  {isLoading ? (
                    <FaSpinner className="spinner-loader" />
                  ) : (
                    <FaUser />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
